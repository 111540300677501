import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';

export const AboutHeroMain = styled.div`
  position: relative;
  ${clamp('padding-top', 250, 470)}
  ${clamp('padding-bottom', 60, 120)}

  h1 {
    position: relative;
    z-index: 3;
  }
`;
