import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';

export const AboutRowMain = styled.div`
  ${clamp('padding-top', 60, 120)}
  ${clamp('padding-bottom ', 60, 120)}
  border-top: ${({ border }) =>
    border ? `0.1rem solid ${colors.humeBlack700}` : undefined};
`;
