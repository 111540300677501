import React from 'react';
import { Heading3 } from '../TextStyles';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import { AboutHeroMain } from './index.style';
import AnimateSplitText from '../_animation/AnimateSplitText';
import Particles from '../Particles';

const AboutHero = ({ text }) => (
  <AboutHeroMain>
    <Particles
      gap={3}
      y={22}
      z={27.3}
      xTarget={-7.24}
      yTarget={9}
      scale={10}
      zDepth={25}
    />
    <Container>
      <Grid>
        <GridItem tabletP={14} tabletPStart={2} tabletL={10} tabletLStart={3}>
          <Heading3 as={`h1`}>
            <AnimateSplitText delay={1} type={`lines`} heading={true}>
              {text}
            </AnimateSplitText>
          </Heading3>
        </GridItem>
      </Grid>
    </Container>
  </AboutHeroMain>
);

export default AboutHero;
