import styled from 'styled-components';
import { mq } from '../../styles/vars/media-queries.style';

export const AboutValueWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4.8rem;

  ${mq.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AboutValueIcon = styled.div`
  position: relative;
  width: calc(33.3333% - 3rem);
  max-width: 11rem;
  margin-right: 3rem;

  ${mq.mobileL} {
    width: 100%;
    max-width: 10rem;
    margin-right: 0;
    margin-bottom: 3.2rem;
  }
`;

export const AboutValueImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

export const AboutValueMain = styled.div`
  width: 66.6666%;

  ${mq.mobileL} {
    width: 100%;
  }
`;
