// Core
import React, { useRef, useEffect } from 'react';
import { AnimationContainer, Animation } from './index.style';
import lottie from 'lottie-web';

import { InView } from 'react-intersection-observer';

const AnimationComponent = (props) => {
  const { data } = props;

  const animationRef = useRef(),
    animationObj = useRef(),
    dataStringRef = useRef();

  const playAnimation = (x) => {
    if (x) {
      animationObj.current.play();
    }
  };

  dataStringRef.current = JSON.stringify(data);

  useEffect(() => {
    let dataAsString = JSON.parse(dataStringRef.current);

    animationObj.current = lottie.loadAnimation({
      container: animationRef.current, // the dom element that will contain the animation
      loop: false,
      autoplay: false,
      animationData: dataAsString, // the path to the animation json
    });

    return () => {
      animationObj.current.destroy();
    };
  }, [animationObj, data]);

  return (
    <InView triggerOnce={true} threshold={0.2} onChange={playAnimation}>
      {({ inView, ref, entry }) => (
        <AnimationContainer ref={ref} inview={inView.toString()}>
          <Animation ref={animationRef}></Animation>
        </AnimationContainer>
      )}
    </InView>
  );
};
export default AnimationComponent;
