import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { mq } from '../../styles/vars/media-queries.style';

export const DrawerMain = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  &:focus {
    outline: none;
  }

  a {
    position: relative;

    svg {
      bottom: 0;
      position: relative;
    }
  }
`;

export const DrawerPanel = styled.div`
  background-color: ${colors.pastelGreen400};
  bottom: 0;
  max-width: 106rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 100%;
`;

export const DrawerClose = styled.button`
  position: absolute;
  ${clamp('top', 22, 42)}
  ${clamp('right', 22, 42)}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  border: 0.1rem solid ${colors.humeBlack700};
  background-color: ${colors.pastelGreen400};
  cursor: pointer;
  z-index: 2;
`;

export const DrawerScroll = styled.div`
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const DrawerInner = styled.div`
  ${clamp('padding-left', 22, 240)}
  ${clamp('padding-right', 22, 240)}
  ${clamp('padding-top', 142, 180)}
  ${clamp('padding-bottom', 42, 180)}

  ${mq.deskL} {
    padding-bottom: 15rem;
    padding-left: 20rem;
    padding-right: 20rem;
    padding-top: 15rem;
  }

  h2 {
    padding-right: 5rem;
  }
`;
