import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { disablePageScroll, enablePageScroll } from '../../utils/utils';
import {
  TeamMemberWrapper,
  TeamMemberImage,
  TeamMemberMain,
  TeamMemberBioToggle,
  SlideMask,
  SlideMaskChild,
} from './index.style';
import { Heading2, Heading5, TextBody, TextCaption } from '../TextStyles';
import Spacer from '../Spacer';
import Portal from '../Portal';
import Drawer from '../Drawer';
import Flow from '../Flow';
import RichText from '../RichText';
import gsap from 'gsap';

// Build ons
import AnimateImageIn from '../_animation/AnimateImageIn';
import AnimateSplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

const TeamMember = ({ name, role, image, bio, index }) => {
  const [showBio, setShowBio] = useState(false);
  const bioRef = useRef();
  const bioToggleRef = useRef();

  const openDrawer = (e) => {
    e.preventDefault();
    setShowBio(true);
    disablePageScroll();
  };

  const closeDrawer = (e) => {
    e.preventDefault();

    gsap.to(bioRef.current, {
      xPercent: 100,
      duration: 0.3,
      ease: 'power3.inOut',
      onComplete: () => {
        setShowBio(false);
        bioToggleRef.current.focus();
      },
    });

    enablePageScroll();
  };

  return (
    <>
      <TeamMemberWrapper id={name.replace(' ', '-')}>
        <TeamMemberImage>
          <AnimateImageIn delay={0.4}>
            <Img fluid={image} />
          </AnimateImageIn>
        </TeamMemberImage>
        <TeamMemberMain>
          <Heading5>
            <AnimateSplitText delay={0.6}>{name}</AnimateSplitText>
          </Heading5>
          <Spacer size={[3, 6]} />
          <TextCaption>
            <AnimateSplitText delay={0.8}>{role}</AnimateSplitText>
          </TextCaption>
          <Spacer size={[18, 24]} />
          <AnimateFadeIn delay={1}>
            <TeamMemberBioToggle ref={bioToggleRef} onClick={openDrawer}>
              <SlideMask>
                <SlideMaskChild>
                  <TextBody>View bio</TextBody>
                </SlideMaskChild>
                <SlideMaskChild>
                  <TextBody>View bio</TextBody>
                </SlideMaskChild>
              </SlideMask>
            </TeamMemberBioToggle>
          </AnimateFadeIn>
        </TeamMemberMain>
      </TeamMemberWrapper>

      {showBio && (
        <Portal>
          <Drawer
            ref={bioRef}
            handleClose={closeDrawer}
            ariaLabel={`Bio for ${name}`}
          >
            <Heading2 as="h2">{name}</Heading2>
            <Spacer size={[30, 120]} />
            <Flow scale={3}>
              <RichText content={bio} />
            </Flow>
          </Drawer>
        </Portal>
      )}
    </>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  image: PropTypes.object,
  bio: PropTypes.object,
};

TeamMember.defaultProps = {};

export default TeamMember;
