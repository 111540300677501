import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import {
  DrawerMain,
  DrawerPanel,
  DrawerScroll,
  DrawerInner,
  DrawerClose,
} from './index.style';
import IconClose from '../_svgs/IconClose';

const Drawer = React.forwardRef(({ ariaLabel, handleClose, children }, ref) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        handleClose(e);
      }
    };

    window.addEventListener('keydown', close);
    ref.current.focus();

    gsap.to(ref.current, {
      x: 0,
      duration: 0.3,
      ease: 'power3.inOut',
    });

    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [ref, handleClose]);

  return (
    <DrawerMain
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel}
      tabIndex="0"
      onClick={handleClose}
      data-inview="true"
    >
      <DrawerPanel
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DrawerClose aria-label="Close" onClick={handleClose}>
          <IconClose />
        </DrawerClose>
        <DrawerScroll>
          <DrawerInner>{children}</DrawerInner>
        </DrawerScroll>
      </DrawerPanel>
    </DrawerMain>
  );
});

Drawer.propTypes = {
  handleClose: PropTypes.func,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
};

export default Drawer;
