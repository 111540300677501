import styled from 'styled-components';
import { mq } from '../../styles/vars/media-queries.style';
import { pxToEm } from '../../styles/utils/conversion.style';
import { easings } from '../../styles/vars/easings.style';

export const TeamMemberWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4.4rem;

  ${mq.mobileL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TeamMemberImage = styled.div`
  width: calc(33.3333% - 2.7rem);
  margin-right: 2.7rem;
  overflow: hidden;
  border-radius: 0.5rem;

  ${mq.mobileL} {
    width: 100%;
    margin-right: 0;
    padding-bottom: 1.6rem;
  }
`;

export const TeamMemberMain = styled.div`
  width: 66.6666%;

  ${mq.mobileL} {
    width: 100%;
  }
`;

export const TeamMemberBioToggle = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid;
`;

export const SlideMask = styled.span`
  cursor: pointer;
  position: relative;
  display: block;
  overflow: hidden;
`;

export const SlideMaskChild = styled.span`
  border-radius: ${pxToEm(32, 16)};
  display: block;
  will-change: transform;
  transition: transform 0.5s ${easings.buttonHover.hover};

  :nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(110%);
  }

  ${mq.desk} {
    a:hover &,
    button:hover & {
      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(0);
      }
    }
  }
`;
