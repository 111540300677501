import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AboutValueWrapper,
  AboutValueIcon,
  AboutValueMain,
} from './index.style';
import { Heading5, TextBodyLarge } from '../TextStyles';
import Spacer from '../Spacer';
import AnimateSplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

import AnimatedSvgIcon from '../AnimatedSvgIcon';

const AboutValue = ({ iconSrc, title, text, index }) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    Promise.all([fetch(iconSrc)])
      .then((responses) => {
        for (const response of responses) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
        }
        return Promise.all(responses.map((response) => response.json()));
      })
      .then((responseData) => {
        setAnimation(responseData[0]);
      })
      .catch((error) => console.error(error));
  }, [iconSrc]);

  return (
    <AboutValueWrapper>
      <AboutValueIcon>
        <AnimateFadeIn delay={0.2}>
          {animation && <AnimatedSvgIcon data={animation} />}
        </AnimateFadeIn>
      </AboutValueIcon>
      <AboutValueMain>
        <Heading5 as="h3">
          <AnimateSplitText delay={0.4}>{title}</AnimateSplitText>
        </Heading5>
        <Spacer size={[6, 12]} />
        <TextBodyLarge>
          <AnimateSplitText delay={0.6}>{text}</AnimateSplitText>
        </TextBodyLarge>
      </AboutValueMain>
    </AboutValueWrapper>
  );
};

AboutValue.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default AboutValue;
