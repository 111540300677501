import React, { useContext, useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import ScrollSection from '../components/ScrollSection';
import Grid from '../components/_layouts/Grid';
import GridItem from '../components/_layouts/GridItem';
import { TextBodyLarge } from '../components/TextStyles';
import AboutRow from '../components/AboutRow';
import Button from '../components/Button';
import Spacer from '../components/Spacer';
import IconArrowheadRight from '../components/_svgs/IconArrowheadRight';
import AboutHero from '../components/AboutHero';
import Container from '../components/_layouts/Container';
import AboutValue from '../components/AboutValue';
import RichText from '../components/RichText';
import TeamMember from '../components/TeamMember';
import Footer from '../components/Footer';

import AnimateSplitText from '../components/_animation/AnimateSplitText';
import AnimateFadeIn from '../components/_animation/AnimateFadeIn';
import PageTransitionLink from '../components/PageTransitionLink';
import Routes from '../routes';
import InViewSection from '../components/InViewSection';
import { ContextState } from 'components/Providers';
import { scrollToSection } from 'utils/utils';

const IndexPage = (props) => {
  const {
    contact,
    intro,
    metaDescription,
    team,
    teamMembers,
    values,
    valuesFooter,
    valuesIntro,
    vision,
  } = props.data.allContentfulPageAbout.nodes[0];
  const [scrolled, setScrolled] = useState(false);
  const { scrollSection } = useContext(ContextState);

  useEffect(() => {
    if (scrollSection && !scrolled) {
      const section = document.getElementById(scrollSection);
      scrollToSection(section);
      // to avoid scrolling when transitioning away from products page
      setScrolled(true);
    }
  }, [scrollSection, scrolled, setScrolled]);

  return (
    <>
      <SEO
        title="About"
        description={metaDescription.metaDescription}
        pathname={`about`}
      />

      <ScrollSection>
        <AboutHero text={intro.intro} />
      </ScrollSection>

      <div data-scroll-section>
        <Container>
          <AboutRow title="Vision" border={false}>
            <InViewSection>
              <TextBodyLarge>
                <AnimateSplitText delay={0.4}>{vision.vision}</AnimateSplitText>
              </TextBodyLarge>
            </InViewSection>
          </AboutRow>
        </Container>
      </div>

      <div data-scroll-section>
        <Container>
          <AboutRow title="Values">
            <InViewSection>
              <TextBodyLarge>
                <AnimateSplitText delay={0.4}>
                  {valuesIntro.valuesIntro}
                </AnimateSplitText>
              </TextBodyLarge>
            </InViewSection>

            <Spacer size={[48, 72]} />

            <Grid mobileL={10} tabletP={8} tabletL={7}>
              {React.Children.toArray(
                values.map((value, index) => (
                  <GridItem
                    mobileL={5}
                    tabletP={4}
                    tabletL={3}
                    tabletLStart={index % 2 === 0 ? undefined : 5}
                  >
                    <InViewSection>
                      <AboutValue
                        iconSrc={value.icon.file.url}
                        title={value.title}
                        text={value.description}
                        index={index}
                      />
                    </InViewSection>
                  </GridItem>
                )),
              )}
            </Grid>

            <Spacer size={[60, 72]} />

            <InViewSection>
              <AnimateFadeIn>
                <RichText content={valuesFooter} />
              </AnimateFadeIn>
            </InViewSection>
          </AboutRow>
        </Container>
      </div>

      <div data-scroll-section>
        <Container>
          <AboutRow title="Team">
            <InViewSection>
              <TextBodyLarge>
                <AnimateSplitText delay={0.4}>{team.team}</AnimateSplitText>
              </TextBodyLarge>
            </InViewSection>

            <Spacer size={[48, 72]} />

            <Grid colCount={7}>
              {React.Children.toArray(
                teamMembers.map((teamMember, teamIndex) => (
                  <GridItem
                    mobileL={3}
                    mobileLStart={teamIndex % 2 === 0 ? undefined : 5}
                  >
                    <InViewSection>
                      <TeamMember
                        image={teamMember.image.fluid}
                        name={teamMember.name}
                        role={teamMember.role}
                        bio={teamMember.bio}
                        index={teamIndex}
                      />
                    </InViewSection>
                  </GridItem>
                )),
              )}
            </Grid>
          </AboutRow>
        </Container>
      </div>

      <div data-scroll-section>
        <Container>
          <AboutRow title="Contact">
            <InViewSection>
              <TextBodyLarge>
                <AnimateSplitText delay={0.4}>
                  {contact.contact}
                </AnimateSplitText>
              </TextBodyLarge>
            </InViewSection>

            <Spacer size={[24, 30]} />

            <InViewSection>
              <AnimateFadeIn delay={1}>
                <PageTransitionLink to={Routes.contact}>
                  <Button iconRight={<IconArrowheadRight />}>Contact Us</Button>
                </PageTransitionLink>
              </AnimateFadeIn>
            </InViewSection>
            <Spacer axis="vertical" size={[60, 120]} />
          </AboutRow>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query AboutPage {
    allContentfulPageAbout {
      nodes {
        metaDescription {
          metaDescription
        }
        intro {
          intro
        }
        vision {
          vision
        }
        valuesIntro {
          valuesIntro
        }
        values {
          icon {
            file {
              url
            }
          }
          title
          description
        }
        valuesFooter {
          raw
        }
        team {
          team
        }
        teamMembers {
          bio {
            raw
          }
          image {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          name
          role
        }
        contact {
          contact
        }
      }
    }
  }
`;
