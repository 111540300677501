import React from 'react';

const IconClose = ({
  width = 12,
  height = 12,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        stroke={fill}
        strokeWidth="2"
        d="M10.563 10.559l-9.12-9.12M10.563 1.44l-9.12 9.12"
      />
    </svg>
  );
};

export default IconClose;
