import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import { Heading4 } from '../TextStyles';
import { AboutRowMain } from './index.style';
import AnimateSplitText from '../_animation/AnimateSplitText';
import InViewSection from '../InViewSection';

const AboutRow = ({ title, children, border = true }) => {
  return (
    <Grid>
      <GridItem tabletP={14} tabletPStart={2} tabletL={12} tabletLStart={3}>
        <AboutRowMain border={border}>
          <Grid tabletP={14} tabletL={12}>
            <GridItem mobileL={6} tabletP={6} tabletL={5}>
              <InViewSection>
                <Heading4 as="h2">
                  <AnimateSplitText delay={0.2}>{title}</AnimateSplitText>
                </Heading4>
              </InViewSection>
            </GridItem>

            <GridItem mobileL={10} tabletP={8} tabletL={7}>
              {children}
            </GridItem>
          </Grid>
        </AboutRowMain>
      </GridItem>
    </Grid>
  );
};

AboutRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  border: PropTypes.bool,
};

export default AboutRow;
